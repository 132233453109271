@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

html{
  font-size: 62.5%;
  box-sizing: border-box;
}

body, #root{
  height: 100vh ;
  margin: 0;
}

body,
input,
select,
button,
textarea{
  font-family: Montserrat, Helvetica, Arial, sans-serif ;
  font-size: 1.4rem;
}

.logo {
    height: 56px;
    margin: 20px; 
    margin-left: 50px;
  }
  
  .logoMini {
      width: 95px;
      height: 70px;
      margin: 10px;
      float: left; 
  }  

  .logoMiniMenu {
    width: 95px;
    height: 70px;
    margin: 15px;
    float: left; 
}
  
  .site-layout .site-layout-background {
    background: #fff;
  }
    [data-theme="dark"] .site-layout .site-layout-background {
      background: #141414;
    } 
  
  .titulo {    
    font: 12px Montserrat, "Source Sans Pro", "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    padding: 10px 0px 10px 4px; 
    color: #fff; 
    text-align: center;       
  }

  .tituloPrincipal {    
    font: 12px Montserrat,"Source Sans Pro", "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    padding: 10px 0px 10px 15px; 
    text-align: center;       
  }
  
  .botonDetalle {
    background-color: #08979c;
    border-Color: #08979c;
  }
  
  .botonDetalle:hover{
    background-color: #13c2c2;
    border-color: #13c2c2;
  }
  
  .botonModificar{
    background-color: #d48806;
    border-color: #d48806;
  }
  
  .botonModificar:hover{
    background-color: #faad14;
    border-color: #faad14;
  }
  
  .login-form {
    max-width: 300px;
  }
  
  .login-form-button {
    width: 100%;
    background: border-box !important;
  }
  
  .centrarLogin{
    position: absolute;
    left: 50%; 
    top: 50%;
    transform: translate(-50%, -50%);
    margin-top: -50px;
    width: 300px;
    text-align: center;
  }
  
  .botonCerrarSesion{
    float: right;
    margin-top: 0px;
    margin-right: 15px;
  }

  .botonAbrirSesion{
    float: right;
    margin-top: 30px;
    margin-right: 15px;
  }
  
  .usuarioSesion{
    margin-top: 4px;
    margin-left: 20px;
  }

  #components-layout-demo-fixed .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.2);
  }

  .site-layout .site-layout-background {
    background: #fff;
  }

  .site-layout-content {
    min-height: 280px;
    padding: 24px;
    background: #fff;
  }
  #components-layout-demo-top .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
  }

  .ant-row-rtl #components-layout-demo-top .logo {
    float: right;
    margin: 16px 0 16px 24px;
  }

  .ant-layout-sider-children{
    font-family: system-ui;
  }

  .ant-layout-header{
    line-height:30px !important;
    height: 104px !important;
  }

  .ant-layout-content{
    background: #fff;
  }

  .ant-breadcrumb a {
    color: rgb(255, 255, 255);
    transition: color 0.3s;
}

  .footerRight{
    float: right!important;
    padding-top: 30px;
    color:#fff;
    margin-right: 100px;
  }

  .footerRightPrincipal{
      float: right!important;
      padding-top: 30px;  
  }  

  .imagen-footer{
    width: 100% !important; 
    vertical-align: bottom !important;
  }

  

  



